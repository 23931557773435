import { Wukong } from '@wukong/bridge-proto'
import { useRef, useState } from 'react'
import {
    DropdownV2,
    MonoIconCommonAdd16 as IconAdd,
    WKIconButton as IconButton,
    MonoIconCommonCancelFullScreen16 as IconCancelFullscreen,
    MonoIconCommonClose16 as IconClose,
    MonoIconCommonColorVariable16 as IconColor,
    MonoIconCommonMore16 as IconDropdown,
    MonoIconDesktopWinFull16 as IconFullscreen,
    MonoIconCommonNumberVariable16 as IconNumber,
    MonoIconCommonInfoLine16 as IconTip,
    InputV2,
    Select,
    TabLoop,
    WKButton,
} from '../../../../../ui-lib/src'
import { environment } from '../../../environment'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { openIndependentWindow } from '../../../kernel/util/open-window'
import { useAppContext } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge/use-view-state'
import { LocalVariableDragProvider, LocalVariableGroupDragProvider } from './local-variable-drag-context'
import { POPUP_HEADER_HEIGHT, useLocalVariableEditorContext } from './local-variable-editor-context'
import { Sidebar } from './local-variable-editor-sidebar'
import { LocalVariableEditorTable } from './local-variable-editor-table'
import { translation } from './local-variable-editor.translation'
import { LocalVariableEmptyIcon } from './local-variable-empty-icon'

function useRenamingCollectionState() {
    const renamingState = useViewState('localVariableEditorRenamingState')
    return renamingState?.type ===
        Wukong.DocumentProto.LocalVariableEditorRenamingType.LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_COLLECTION
        ? renamingState.renamingCollectionId
        : null
}

function CollectionSelector(props: {
    collections: Wukong.DocumentProto.ILocalVariableSet[]
    selectedCollection: Wukong.DocumentProto.ILocalVariableSet
}) {
    const service = useAppContext().variableService.localVariableEditorService
    if (props.collections.length === 1) {
        return <span style={{ fontWeight: 'var(--wk-font-weight-semibold)' }}>{props.selectedCollection.name}</span>
    }
    return (
        <Select.MinimalSingleLevel
            maxWidth={420}
            label={
                <span style={{ fontWeight: 'var(--wk-font-weight-semibold)' }}>{props.selectedCollection.name}</span>
            }
            value={props.selectedCollection.id}
            onChange={service.selectCollection}
            dataTestIds={{
                triggerFocus: 'local-variable-editor-header-collection-select',
            }}
        >
            {props.collections.map((collection) => (
                <Select.MinimalSingleLevel.Option key={collection.id} value={collection.id}>
                    <span
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        data-testid="local-variable-editor-header-collection-select-option"
                    >
                        {collection.name}
                    </span>
                </Select.MinimalSingleLevel.Option>
            ))}
        </Select.MinimalSingleLevel>
    )
}

function CollectionControl() {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedCollection = useViewState('localVariableCollection')?.currentCollection ?? null
    const collections = useViewState('localVariableCollection')?.collections ?? []
    const isEditing = useRenamingCollectionState()
    if (selectedCollection) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'var(--wk-v2-label-color-gray-13)',
                    marginLeft: isEditing ? '8px' : '16px',
                    gap: '6px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {isEditing ? (
                    <InputV2
                        value={selectedCollection.name}
                        onBlur={(e) => {
                            service.renameCollectionSubmit(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation()
                        }}
                        autoFocus
                        dataTestIds={{
                            input: 'local-variable-editor-header-collection-name-input',
                        }}
                    />
                ) : (
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: 1,
                            maxWidth: 142 + 4 + 12, // 4 是 gap，12 是 icon 宽度
                        }}
                        data-testid="local-variable-header-collection-name"
                    >
                        <CollectionSelector collections={collections} selectedCollection={selectedCollection} />
                    </div>
                )}
                <DropdownV2.IconSingleLevel
                    label={<IconDropdown />}
                    onChange={(value: string) => {
                        switch (value) {
                            case translation('下拉菜单添加合集'):
                                service.createCollection()
                                break
                            case translation('下拉菜单重命名合集'):
                                service.renameCollection()
                                break
                            case translation('下拉菜单删除合集'):
                                service.removeCollection()
                                break
                        }
                    }}
                    dataTestIds={{
                        triggerFocus: 'local-variable-editor-header-collection-dropdown-icon',
                    }}
                >
                    <DropdownV2.IconSingleLevel.Option value={translation('下拉菜单添加合集')}>
                        <span data-testid="local-variable-editor-header-collection-dropdown-create">
                            {translation('下拉菜单添加合集')}
                        </span>
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option value={translation('下拉菜单重命名合集')} splitLineBottom>
                        <span data-testid="local-variable-editor-header-collection-dropdown-rename">
                            {translation('下拉菜单重命名合集')}
                        </span>
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option value={translation('下拉菜单删除合集')}>
                        <span data-testid="local-variable-editor-header-collection-dropdown-delete">
                            {translation('下拉菜单删除合集')}
                        </span>
                    </DropdownV2.IconSingleLevel.Option>
                </DropdownV2.IconSingleLevel>
            </div>
        )
    }
    return (
        <div style={{ color: 'var(--wk-v2-label-color-gray-13)', marginLeft: '16px' }}>
            {translation('本地变量弹框标题')}
        </div>
    )
}

function NoneVariableCreateControl() {
    const service = useAppContext().variableService.localVariableEditorService
    const triggerRef = useRef<HTMLButtonElement>(null)
    const [openState, setOpenState] = useState<DOMRect | null>(null)
    const toggleDropdown = () => {
        setOpenState((prev) => (prev ? null : triggerRef.current?.getBoundingClientRect() || null))
    }
    return (
        <>
            <WKButton
                type="primary"
                size="small"
                dataTestId="local-variable-editor-empty-create-button-dropdown"
                onClick={toggleDropdown}
                ref={triggerRef}
            >
                {translation('创建变量')}
            </WKButton>
            {openState && (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState
                    triggerRect={openState}
                    placement="bottom left"
                    onChange={(value: string) => {
                        switch (value) {
                            case translation('创建颜色变量'):
                                service.createVariable(
                                    Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR
                                )
                                break
                            case translation('创建数值变量'):
                                service.createVariable(
                                    Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT
                                )
                                break
                        }
                    }}
                    onClose={() => setOpenState(null)}
                >
                    <DropdownV2.IconSingleLevel.Option
                        value={translation('创建颜色变量')}
                        dataTestId="local-variable-editor-empty-create-button"
                    >
                        <div
                            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                            className="color-$wk-v2-label-color-gray-13 wk-text-12"
                        >
                            <IconColor />
                            {translation('创建颜色变量')}
                        </div>
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={translation('创建数值变量')}
                        dataTestId="local-variable-editor-empty-create-button-2"
                    >
                        <div
                            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                            className="color-$wk-v2-label-color-gray-13 wk-text-12"
                        >
                            <IconNumber />
                            {translation('创建数值变量')}
                        </div>
                    </DropdownV2.IconSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            )}
        </>
    )
}
function NoneVariable() {
    const service = useAppContext().variableService.localVariableEditorService
    const gotoHelpDoc = () => {
        openIndependentWindow(environment.onboardingHelpDocUrl.variable)
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
            }}
            data-testid="local-variable-editor-empty"
        >
            <div style={{ flex: 0.3 }}></div>
            <div
                style={{
                    height: '284px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                }}
            >
                <LocalVariableEmptyIcon />
                <div
                    style={{
                        color: 'var(--wk-v2-label-color-gray-13)',
                        fontSize: 'var(--wk-font-size-body)',
                        textAlign: 'center',
                    }}
                >
                    <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{translation('空数据描述1')}</p>
                    <p style={{ margin: 0, whiteSpace: 'pre-line' }}>
                        {translation('空数据描述2')}
                        <a onClick={gotoHelpDoc} style={{ cursor: 'default' }}>
                            {translation('空数据了解更多')}
                        </a>
                    </p>
                </div>
                {featureSwitchManager.isEnabled('float-variable') ? (
                    <NoneVariableCreateControl />
                ) : (
                    <WKButton
                        type="primary"
                        size="small"
                        icon={<IconAdd />}
                        onClick={() =>
                            service.createVariable(
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR
                            )
                        }
                        data-testid="local-variable-editor-empty-create-button"
                    >
                        {translation('创建颜色变量')}
                    </WKButton>
                )}
            </div>
        </div>
    )
}

export function LocalVariableEditor() {
    const service = useAppContext().variableService.localVariableEditorService
    const collections = useViewState('localVariableCollection')?.collections ?? []
    const varibalesCount = useViewState('localVariableGroup')?.variablesInCollectionCount ?? 0
    const { popupPointerDown, popupPointerMove, popupPointerUp, popupFullscreenState, popupToggleFullscreen } =
        useLocalVariableEditorContext()
    const isUnderFullscreen = !!popupFullscreenState

    const onContentDoubleClick = (e: React.PointerEvent<HTMLDivElement>) => {
        // 如果点击的不是当前元素，则不处理
        if (e.target !== e.currentTarget) {
            return
        }
        e.stopPropagation()
        popupToggleFullscreen()
    }
    const onCententPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        // 如果点击的不是当前元素，则不处理
        if (e.target !== e.currentTarget) {
            return
        }
        const element = e.target as HTMLDivElement
        element.setPointerCapture(e.pointerId)
        popupPointerDown(e.clientX, e.clientY)
    }
    const onContentPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const element = e.target as HTMLDivElement
        if (!element.hasPointerCapture(e.pointerId)) {
            return
        }
        popupPointerMove(e.clientX, e.clientY)
    }
    const onContentPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const element = e.target as HTMLDivElement
        if (element.hasPointerCapture(e.pointerId)) {
            element.releasePointerCapture(e.pointerId)
        }
        popupPointerUp()
    }

    return (
        <TabLoop>
            <div
                data-testid="local-variable-editor-content"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {/* 标题栏 */}
                <div
                    data-testid="local-variable-editor-content-header"
                    style={{
                        minHeight: POPUP_HEADER_HEIGHT,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid var(--wk-gray-3)',
                    }}
                    onPointerDown={onCententPointerDown}
                    onPointerMove={onContentPointerMove}
                    onPointerUp={onContentPointerUp}
                    onDoubleClick={onContentDoubleClick}
                >
                    {/* 左侧合集编辑器 */}
                    <CollectionControl />
                    {/* 右侧按钮 */}
                    <div
                        data-testid="local-variable-editor-content-header-right"
                        style={{
                            display: 'flex',
                            marginRight: '10px',
                            gap: '4px',
                        }}
                    >
                        <div style={{ color: 'var(--wk-v2-label-color-gray-8)' }}>
                            <IconButton
                                icon={isUnderFullscreen ? <IconCancelFullscreen /> : <IconFullscreen />}
                                onClick={popupToggleFullscreen}
                                data-testid="local-variable-editor-content-header-fullscreen-icon"
                                data-fullscreen={isUnderFullscreen}
                            />
                        </div>
                        <div style={{ color: 'var(--wk-v2-label-color-gray-8)' }}>
                            <IconButton
                                icon={<IconClose />}
                                onClick={service.popupToggle}
                                data-testid="local-variable-editor-close"
                            />
                        </div>
                    </div>
                </div>
                <div
                    data-testid="local-variable-editor-content-body"
                    style={{
                        flexGrow: 1,
                        minHeight: '0',
                    }}
                    onPointerDown={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {/* 有数据时 */}
                    {collections.length > 0 ? (
                        <div style={{ width: '100%', height: '100%' }}>
                            <div style={{ display: 'contents' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '100%',
                                        overflow: 'hidden',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <LocalVariableDragProvider>
                                        <div
                                            data-testid="local-variable-editor-sidebar"
                                            style={{
                                                width: '188px',
                                                flexShrink: 0,
                                                background: '#F7F8FA',
                                                borderRight: '1px solid var(--wk-gray-3)',
                                            }}
                                        >
                                            {/* 侧边栏 */}
                                            <LocalVariableGroupDragProvider>
                                                <Sidebar />
                                            </LocalVariableGroupDragProvider>
                                        </div>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                overflow: 'hidden',
                                                alignItems: 'stretch',
                                            }}
                                        >
                                            {varibalesCount > 0 ? (
                                                <>
                                                    {/* 表格 */}
                                                    <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                                        <LocalVariableEditorTable />
                                                    </div>

                                                    {/* 底部按钮 */}
                                                    <div
                                                        style={{
                                                            height: 'calc(52px - 2 * 12px)',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            padding: '12px 16px',
                                                            borderTop: '1px solid var(--wk-gray-3)',
                                                        }}
                                                    >
                                                        <div>
                                                            <WKButton
                                                                type="secondary"
                                                                icon={
                                                                    featureSwitchManager.isEnabled('float-variable') ? (
                                                                        <IconColor />
                                                                    ) : (
                                                                        <IconAdd />
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    service.createVariable(
                                                                        Wukong.DocumentProto.VariableResolvedDataType
                                                                            .VARIABLE_RESOLVED_DATA_TYPE_COLOR
                                                                    )
                                                                }
                                                                data-testid="local-variable-editor-table-footer-create-button"
                                                            >
                                                                {featureSwitchManager.isEnabled('float-variable')
                                                                    ? translation('创建颜色变量简写')
                                                                    : translation('创建颜色变量')}
                                                            </WKButton>
                                                            {featureSwitchManager.isEnabled('float-variable') ? (
                                                                <WKButton
                                                                    type="secondary"
                                                                    icon={<IconNumber />}
                                                                    onClick={() =>
                                                                        service.createVariable(
                                                                            Wukong.DocumentProto
                                                                                .VariableResolvedDataType
                                                                                .VARIABLE_RESOLVED_DATA_TYPE_FLOAT
                                                                        )
                                                                    }
                                                                    data-testid="local-variable-editor-table-footer-create-button-2"
                                                                >
                                                                    {translation('创建数值变量简写')}
                                                                </WKButton>
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <IconTip
                                                                style={{
                                                                    color: 'var(--wk-gray-6)',
                                                                    marginRight: '4px',
                                                                }}
                                                            />
                                                            <span style={{ color: 'var(--wk-gray-11)' }}>
                                                                {translation('更多操作')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <NoneVariable />
                                            )}
                                        </div>
                                    </LocalVariableDragProvider>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // 无合集时
                        <NoneVariable />
                    )}
                </div>
            </div>
        </TabLoop>
    )
}
