/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import { MonoIconPanelStrokeWeight16 } from '../../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../../window'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { useFloatVariablePanel } from '../../primitive-variable/use-float-variable-panel'
import { StrokeAllBorderWeightRef, useStrokeAllBorderWeightV2 } from './use-stroke-all-border-weight'

// 全线重输入框
const _StrokeAllBorderWeight = (
    { isOpenFourIndependentWeight }: { isOpenFourIndependentWeight: boolean },
    ref: ForwardedRef<StrokeAllBorderWeightRef>
) => {
    const [typeState, setTypeState] = useState<Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined>()
    const { allBorderState, allBorderVariable, onChangeWeight, onAttachFloatVar, onDetachFloatVar } =
        useStrokeAllBorderWeightV2({
            isOpenFourIndependentWeight,
            typeState,
        })

    const strokeBorderWeightVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.ALL_SCOPES],
        selectedVariable: allBorderVariable,
        selectedVariableFallbackFloatValue: allBorderState?.value ?? 0,
        createEnable: true,
        defaultCreateValue: allBorderState?.value ?? 0,
        onVariableSelected: onAttachFloatVar,
        onVariableDetach: onDetachFloatVar,
    })

    useImperativeHandle(
        ref,
        () => ({
            setTypeState,
        }),
        []
    )

    return allBorderState &&
        allBorderState.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY ? (
        <>
            <ScrubbableInputNumber
                labelTestId={EditorDataTestId.StrokeAttr.WeightInputLabel}
                icon={<MonoIconPanelStrokeWeight16 />}
                isMixed={allBorderState.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED}
                value={allBorderState.value || undefined}
                onChange={onChangeWeight}
                min={0}
                scrubbingDisabled={
                    allBorderState.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                }
                testId={EditorDataTestId.StrokeAttr.WeightInput}
                useVariable={strokeBorderWeightVariablePicker.useVariable}
                inputStyle="small-indent"
            />
            {strokeBorderWeightVariablePicker.renderPanel()}
        </>
    ) : (
        <></>
    )
}

export const StrokeAllBorderWeight = forwardRef(_StrokeAllBorderWeight)
