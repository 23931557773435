import constate from 'constate'
import { useAIGenUIInputs } from '../../ai/ai-gen-ui/use-ai-gen-inputs'
import { usePlatformAndDesignSystem } from '../../ai/ai-gen-ui/use-ai-gen-platform-and-design-system'

function useChatbotInput() {
    const images = useAIGenUIInputs({})
    const designSystem = usePlatformAndDesignSystem({})
    return {
        ...images,
        ...designSystem,
    }
}

const [ChatbotInputProvider, useChatbotInputContext] = constate(useChatbotInput)

export { ChatbotInputProvider, useChatbotInputContext }
