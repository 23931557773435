import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Badge,
    DataNode,
    DropdownDefaultCustomNode,
    DropdownDefaultRenderItem,
    DropdownV2,
    MonoIconToolbarAI24,
    MonoIconToolbarAICheck24,
    MonoIconToolbarAICreator24,
    MonoIconToolbarAIDuplication24,
    MonoIconToolbarAIGeneratesUI24,
    MonoIconToolbarAILayout24,
    MonoIconToolbarAIMaintainer24,
    MonoIconToolbarAISeach24,
    MonoIconToolbarArrow24,
    MonoIconToolbarComment24,
    MonoIconToolbarFrame24,
    MonoIconToolbarGripper24,
    MonoIconToolbarImage24,
    MonoIconToolbarInspect24,
    MonoIconToolbarLine24,
    MonoIconToolbarMagic24,
    MonoIconToolbarMove24,
    MonoIconToolbarOval24,
    MonoIconToolbarPen24,
    MonoIconToolbarRectangle24,
    MonoIconToolbarSlice24,
    MonoIconToolbarStar24,
    MonoIconToolbarTriangle24,
    MonoIconToolbarType24,
    MonoIconToolbarZoom24,
    MulticolorIconToolbarAIseacted24,
    RenderStructNodeItem,
    RenderStructNodeItemProps,
    Tooltip,
    useEllipsisTooltip,
    wkDialogConfirm,
} from '../../../../../../ui-lib/src'
import { domLocation, generateRouterPath, RouteToken } from '../../../../../../util/src'
import { EditorMode } from '../../../../document/node/node'
import { WKFrog } from '../../../../kernel/frog'
import { PluginVO } from '../../../../kernel/interface/plugin'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { ResourceType } from '../../../../kernel/interface/type'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { openIndependentWindow } from '../../../../kernel/util/open-window'
import { AddAiCopilotPlaygroundRequest } from '../../../../main/ai-service/requests'
import { UserAiSceneCreditsVO } from '../../../../main/ai-service/typings'
import { useAppContext, usePlanAndUserStateService, usePluginService } from '../../../../main/app-context'
import { featureGuideService } from '../../../../main/service/feature-guide/feature-guide-service'
import { AiRecognizeModalForInEditor } from '../../../../space/app/ai-recognize/ai-recognize-modal'
import { useViewState } from '../../../../view-state-bridge'
import { useCanvasRenderBridge } from '../../../context/document-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../utils/tools-actions-frog'
import { useAIGenUIFrog } from '../../ai/ai-gen-ui/ai-gen-ui-princi'
import { isAISearchEnabled } from '../../ai/ai-search/ai-search-feature'
import { Switch } from '../../atom/switch/switch'
import { AiRecognizeModal as AiRecognizeModalForMaintain } from '../../component-style-library-v2/ai-library-maintain-modal/ai-recognize-modal'
import { useLastUsedPlugin } from '../../plugin/last-used-plugin'
import { getEditorModeName, getEditorModeShortCut } from '../utils'
import { AiToolHeader } from './ai-tool-header'
import { AiToolTitle } from './ai-tool-title'
import styles from './background.module.less'
import { translation } from './normal-tool.translation'
import style from './toolbar.module.less'
import { useToolbar } from './use-toolbar'
import { featureSwitchManager } from '../../../../kernel/switch'

export function DevModeTool() {
    const { editorMode, hasUnreadComment, onSwitchChangeForDevMode } = useToolbar()
    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChangeForDevMode}>
            <Switch.Item
                className={`${style.switchItem44}`}
                key={EditorMode.EM_DevModeInspect}
                tooltipTitle={getEditorModeName(EditorMode.EM_DevModeInspect)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_DevModeInspect)}
                testid="dev-mode-inspect"
            >
                <MonoIconToolbarInspect24 />
            </Switch.Item>
            <Switch.Item
                className={`${style.switchItem44} wk-comment-tool`}
                key={EditorMode.EM_Comment}
                tooltipTitle={getEditorModeName(EditorMode.EM_Comment)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Comment)}
                testid="dev-mode-comment"
            >
                <span
                    className={classNames(style.comment, {
                        [style.unread]: hasUnreadComment,
                    })}
                >
                    <MonoIconToolbarComment24 />
                </span>
            </Switch.Item>
        </Switch>
    )
}

interface AiToolDataNode extends DropdownDefaultCustomNode {
    isTitle?: boolean
    handler?: () => void
    isHeader?: boolean
    aiCredit?: UserAiSceneCreditsVO
}

export const AiToolMenuItemRender: RenderStructNodeItem<AiToolDataNode> = (
    props: RenderStructNodeItemProps<AiToolDataNode>
) => {
    if (props.data.customNode?.isTitle) {
        return <AiToolTitle />
    } else if (props.data.customNode?.isHeader) {
        return <AiToolHeader name={props.data.name} aiCredit={props.data.customNode.aiCredit!} />
    }
    return <DropdownDefaultRenderItem {...props} />
}

export function DesignModeTool() {
    const {
        adjustTool,
        rangeTool,
        aiToolV2,
        drawTool,
        pluginTool,
        editorMode,
        hasUnreadComment,
        isPluginPopup,
        onSwitchChange,
        isSelect,
        onSwitchImage,
        onPluginPopupChange,
    } = useToolbar()
    const pluginService = usePluginService()
    const publishedPlugins = pluginService.states.use.publishedPlugins()
    const isGuest = pluginService.states.use.isGuest()
    const lastUsedPlugin = useLastUsedPlugin()

    const { clickAiGenUiToolbarEntry } = useAIGenUIFrog()

    const onEnterChangeEditMode = useCallback(
        (value: EditorMode) => {
            onSwitchChange(value)
        },
        [onSwitchChange]
    )

    const onEnterChangePluginMode = useCallback(
        (value: Wukong.DocumentProto.PluginType | string) => {
            if (typeof value === 'string') {
                if (value === 'last-used-plugin') {
                    lastUsedPlugin.onClick()
                } else if (value === 'plugin-management') {
                    pluginService.states.getState().updateIsOpenPluginManagement(true)
                } else {
                    pluginService.runPublishedPlugin(value)
                }
            } else {
                pluginService.showModal(value)
                lastUsedPlugin.onUserClickPlugin(value)
            }
        },
        [lastUsedPlugin, pluginService]
    )

    const aiService = useAppContext().aiService
    const aiDesignLintService = useAppContext().aiDesignLintService
    const enableMagicCopilot = aiService.useZustandStore.use.enableMagicCopilot()
    const enableAIPrototype = useFeatureSwitch('ai-gen-prototype')
    const [showAiMaintainModal, setShowAiMaintainModal] = useState(false)
    const [confirmCancelAiRecognizeModalForMaintainVisible, setConfirmCancelAiRecognizeModalForMaintainVisible] =
        useState(false)
    const [aiRecognizeModalVisible, setAiRecognizeHintModalVisible] = useState(false)
    const [confirmCancelAiRecognizeModalVisible, setConfirmCancelAiRecognizeModalVisible] = useState(false)
    const showExitAILayout = useViewState('actionEnabledExitAiLayoutStatus')
    const docContext = useDocInfoContext()
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()
    const isEnterprise = planAndUserState?.planType == 'enterprise'
    const canvasRenderBridge = useCanvasRenderBridge()
    const pluginManagementVisible =
        publishedPlugins.length > 0 && docContext.docData?.orgId && docContext.docData?.orgId !== '-1' && !isGuest

    const isOrgLock = useMemo(() => {
        return planAndUserState?.resourceType === ResourceType.Organization && planAndUserState.planFreeze
    }, [planAndUserState?.planFreeze, planAndUserState?.resourceType])

    const onClickCancelRecognizeForMaintain = () => {
        setConfirmCancelAiRecognizeModalForMaintainVisible(true)
        wkDialogConfirm.show({
            title: translation('AreYouSure'),
            okButtonProps: { type: 'primary', danger: true },
            okText: translation('Cancel'),
            cancelText: translation('Continue'),
            onOk: () => {
                setConfirmCancelAiRecognizeModalForMaintainVisible(false)
                setShowAiMaintainModal(false)
            },
            onClose: () => {
                setConfirmCancelAiRecognizeModalForMaintainVisible(false)
            },
        })
    }
    const onClickCancelRecognize = () => {
        setConfirmCancelAiRecognizeModalVisible(true)
        wkDialogConfirm.show({
            title: translation('AreYouSure'),
            okButtonProps: { type: 'primary', danger: true },
            okText: translation('Cancel'),
            cancelText: translation('Continue'),
            onOk: () => {
                setConfirmCancelAiRecognizeModalVisible(false)
                setAiRecognizeHintModalVisible(false)
            },
            onClose: () => {
                setConfirmCancelAiRecognizeModalVisible(false)
            },
        })
    }

    const onAiToolsChange = (dataNode: DataNode<AiToolDataNode>) => {
        dataNode.customNode?.handler?.()
    }

    const aiToolBoxCredits = aiService.useZustandStore.use.aiToolBoxCredits()
    const aiDesignSystemCredits = aiService.useZustandStore.use.aiDesignSystemCredits()
    const aiLabCredits = aiService.useZustandStore.use.aiLabCredits()

    useEffect(() => {
        // 当编辑模式工具栏消失的时候，尝试移除 ai 复制的引导
        return () => {
            featureGuideService.tryRemove('aiDuplicate')
        }
    }, [])

    const aiToolItems: DataNode<AiToolDataNode>[] = []
    aiToolItems.push({
        name: '',
        disabled: true,
        customNode: {
            isTitle: true,
        },
    })
    aiToolItems.push({
        name: translation('AiToolBox'),
        disabled: true,
        customNode: {
            isHeader: true,
            aiCredit: aiToolBoxCredits,
        },
    })
    aiToolItems.push({
        name: translation('AiRedupliation'),
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarAIDuplication24 />,
            },
            shortcut: shortcutLabelMap[ShortcutKey.AiDuplicate],
            handler: () => {
                WKFrog.addFrogRecord({
                    url: '/event/AiDuplicate/aiDuplicateToolSelect',
                    eventId: 24062,
                    currentPage: 'AiDuplicate',
                    eventAction: 'event',
                    eventName: 'aiDuplicateToolSelect',
                    customExtend: {
                        select_way: '1',
                    },
                })
                aiService.trySwitchToAiDuplicate()
            },
        },
    })
    aiToolItems.push({
        name: translation('AILayout'),
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarAILayout24 />,
            },
        },
        dataTestId: 'ai-layout-entry',
        children: [
            {
                name: translation('StructuredTemporarily'),
                disabled: showExitAILayout,
                dataTestId: 'ai-layout-StructuredTemporarily',
                customNode: {
                    shortcut: shortcutLabelMap[ShortcutKey.EnterAiLayout],
                    handler: () => {
                        aiService.tryEnterAiLayout()
                    },
                },
            },
            {
                name: translation('SmartlyAddAutoLayout'),
                disabled: showExitAILayout,
                customNode: {
                    shortcut: shortcutLabelMap[ShortcutKey.SmartAddAutoLayout],
                    handler: () => {
                        aiService.trySmartAddAutoLayout()
                    },
                },
            },
        ],
    })

    if (isAISearchEnabled()) {
        aiToolItems.push({
            name: translation('AISearch'),
            customNode: {
                customItemProps: {
                    forwardChildren: <MonoIconToolbarAISeach24 />,
                },
                handler: () => {
                    aiService.tryEnterAiSearch()
                },
            },
            dataTestId: 'ai-search-entry',
        })
    }

    aiToolItems.push({
        name: '',
        disabled: true,
        customNode: {
            autoSplitLine: true,
        },
    })

    aiToolItems.push({
        name: translation('AIDesignSystem'),
        disabled: true,
        customNode: {
            isHeader: true,
            aiCredit: aiDesignSystemCredits,
        },
    })

    !isOrgLock &&
        aiToolItems.push({
            name: translation('AIDesignSystemCreator'),
            dataTestId: 'ai-design-system-creator',
            customNode: {
                customItemProps: {
                    forwardChildren: <MonoIconToolbarAICreator24 />,
                },
                handler: () => {
                    if (aiService.tryUseAiDesignSystem()) {
                        setAiRecognizeHintModalVisible(true)
                    }
                },
            },
        })

    aiToolItems.push({
        name: translation('AIDesignSystemMaintainer'),
        dataTestId: 'ai-library-maintain-tool',
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarAIMaintainer24 />,
            },
            handler: () => {
                if (aiService.tryUseAiDesignSystem()) {
                    setShowAiMaintainModal(true)
                }
            },
        },
    })

    aiToolItems.push({
        name: translation('AIConsistencyChecker'),
        dataTestId: 'ai-design-lint-entry',
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarAICheck24 />,
            },
            handler: () => {
                if (aiService.tryUseAiDesignSystem()) {
                    aiDesignLintService.refresh()
                    aiDesignLintService.showModal()
                }
            },
        },
    })

    aiToolItems.push({
        name: '',
        disabled: true,
        customNode: {
            autoSplitLine: true,
        },
    })

    aiToolItems.push({
        name: translation('MotiffLab'),
        disabled: true,
        customNode: {
            isHeader: true,
            aiCredit: aiLabCredits,
        },
    })

    const aiDesignSystemOnboarding = useFeatureSwitch('ai-gen-onboarding-design-system')

    aiToolItems.push({
        name: translation('AiGenUI'),
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarAIGeneratesUI24 />,
                backwardChildren: aiDesignSystemOnboarding ? (
                    <div className="h-full flex justify-center items-center">
                        <div
                            style={{ background: 'linear-gradient(101.485085deg, #ff87a0 2%, #ff335e 98%)' }}
                            className="rounded px-1 h-4 w-5.25 flex justify-center items-center"
                        >
                            <span className="text-3 line-height-5 font-500 text-$wk-white!">Hot</span>
                        </div>
                    </div>
                ) : (
                    <></>
                ),
            },
            handler: () => {
                clickAiGenUiToolbarEntry()
                if (aiService.tryUseAiLab()) {
                    aiService.openAIGenUIModal()
                }
            },
        },
    })

    if (enableAIPrototype) {
        aiToolItems.push({
            name: 'AIGenPrototype',
            customNode: {
                handler: () => {
                    aiService.openAIGenPrototypeModal(true, canvasRenderBridge)
                },
            },
        })
    }

    aiToolItems.push({
        name: translation('AIMagicBox'),
        dataTestId: 'ai-magic-box',
        customNode: {
            customItemProps: {
                forwardChildren: <MonoIconToolbarMagic24 />,
            },
            shortcut: shortcutLabelMap[ShortcutKey.Copilot],
            handler: () => {
                if (enableMagicCopilot) {
                    aiService.trySwitchToAiCopilot()
                } else {
                    wkDialogConfirm.info({
                        title: translation('AiMagicHint1'),
                        content: translation('AiMagicHint2'),
                        cancelText: translation('CancelAiMagic'),
                        okText: translation('OpenPlayground'),
                        onOk: async () => {
                            const orgId = docContext.getCurrentDocOrganization()?.id
                            if (!orgId) {
                                return
                            }
                            const newDoc = await new AddAiCopilotPlaygroundRequest(orgId).start()
                            openIndependentWindow(
                                `${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${newDoc.id}`
                            )
                        },
                    })
                }
            },
        },
    })

    const [aiToolsOpened, setAiToolsOpened] = useState(false)
    const aiToolLabel = useMemo(() => {
        if (aiToolsOpened) {
            return <MulticolorIconToolbarAIseacted24 />
        } else {
            return <MonoIconToolbarAI24 />
        }
    }, [aiToolsOpened])

    useEffect(() => {
        if (featureSwitchManager.isEnabled('ai-gen-onboarding-design-system')) {
            const clear = featureGuideService.tryStart('aiGenDesignSystem')
            return () => {
                clear?.()
            }
        }
    }, [])

    const getAiTools = () => {
        return (
            <>
                <DropdownV2.MenuMultiLevel
                    tooltipProps={{
                        title: 'Motiff AI',
                    }}
                    id="wk-ai-menu"
                    className={style.aiTool}
                    removeTopPadding={true}
                    onOpen={() => {
                        WKFrog.addFrogRecord({
                            url: '/expose/Editor/aiFunctionListExpose',
                            eventId: 27706,
                            currentPage: 'Editor',
                            eventAction: 'expose',
                            eventName: 'aiFunctionListExpose',
                        })
                        setAiToolsOpened(true)
                    }}
                    onClose={() => setAiToolsOpened(false)}
                    label={aiToolLabel}
                    items={aiToolItems}
                    onChange={onAiToolsChange}
                    placement="bottom center"
                    key={String(!!aiToolV2)}
                    renderItem={AiToolMenuItemRender}
                    dataTestIds={{
                        triggerFocus: 'ai-tools-entry',
                    }}
                ></DropdownV2.MenuMultiLevel>
                {showAiMaintainModal && (
                    <AiRecognizeModalForMaintain
                        visible={showAiMaintainModal && !confirmCancelAiRecognizeModalForMaintainVisible}
                        onCancel={() => {
                            setShowAiMaintainModal(false)
                        }}
                        isEnterprise={isEnterprise}
                        onClickCancelRecognize={onClickCancelRecognizeForMaintain}
                    />
                )}
                {aiRecognizeModalVisible ? (
                    <AiRecognizeModalForInEditor
                        visible={aiRecognizeModalVisible && !confirmCancelAiRecognizeModalVisible}
                        onCancel={() => {
                            setAiRecognizeHintModalVisible(false)
                        }}
                        onClickCancelRecognize={onClickCancelRecognize}
                        isEnterprise={isEnterprise}
                    ></AiRecognizeModalForInEditor>
                ) : undefined}
            </>
        )
    }
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')
    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChange}>
            <DropdownV2.MenuSingleLevel
                style={{ borderRadius: 'var(--wk-radius-none)' }}
                placement="bottom center"
                onChange={onEnterChangeEditMode}
                label={adjustTool.icon}
                onOpen={() => {
                    frogExposeToolsMenusAndActionsMenuDisplay({
                        doc_mode: {
                            is_readonly: !!docReadonly,
                            is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                            is_history_version: !!isHistoryMode,
                            is_vector: !!isEditVector,
                        },
                        displayed_menu: '移动工具下拉菜单',
                    })
                }}
                tooltipProps={{ title: translation('MoveTools') }}
                labelMoveOut={{
                    isSelect: !isPluginPopup && adjustTool.editorMode === editorMode,
                    tooltipProps: {
                        title: getEditorModeName(adjustTool.editorMode),
                        shortcut: getEditorModeShortCut(adjustTool.editorMode),
                    },
                    onClick: () => onSwitchChange(adjustTool.editorMode),
                }}
                className={
                    editorMode != null && [EditorMode.EM_Move, EditorMode.EM_Scale].includes(editorMode)
                        ? styles['active-background']
                        : undefined
                }
            >
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Move}
                    isSelect={isSelect(EditorMode.EM_Move)}
                    forwardChildren={<MonoIconToolbarMove24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Move)}
                >
                    {getEditorModeName(EditorMode.EM_Move)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Scale}
                    isSelect={isSelect(EditorMode.EM_Scale)}
                    forwardChildren={<MonoIconToolbarZoom24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Scale)}
                >
                    {getEditorModeName(EditorMode.EM_Scale)}
                </DropdownV2.MenuSingleLevel.Option>
            </DropdownV2.MenuSingleLevel>
            <DropdownV2.MenuSingleLevel
                id="wk-frame-dropdown"
                style={{ borderRadius: 'var(--wk-radius-none)' }}
                placement="bottom center"
                onChange={onEnterChangeEditMode}
                onOpen={() => {
                    frogExposeToolsMenusAndActionsMenuDisplay({
                        doc_mode: {
                            is_readonly: !!docReadonly,
                            is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                            is_history_version: !!isHistoryMode,
                            is_vector: !!isEditVector,
                        },
                        displayed_menu: '区域工具下拉菜单',
                    })
                }}
                label={rangeTool.icon}
                tooltipProps={{ title: translation('RegionTools') }}
                labelMoveOut={{
                    isSelect: !isPluginPopup && rangeTool.editorMode === editorMode,
                    tooltipProps: {
                        title: getEditorModeName(rangeTool.editorMode),
                        shortcut: getEditorModeShortCut(rangeTool.editorMode),
                    },
                    onClick: () => onSwitchChange(rangeTool.editorMode),
                }}
                className={
                    editorMode != null && [EditorMode.EM_Frame, EditorMode.EM_Slice].includes(editorMode)
                        ? styles['active-background']
                        : undefined
                }
                dataTestIds={{ triggerFocus: 'draw-range-down' }}
            >
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Frame}
                    isSelect={isSelect(EditorMode.EM_Frame)}
                    forwardChildren={<MonoIconToolbarFrame24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Frame)}
                    data-testid="draw-frame"
                >
                    {getEditorModeName(EditorMode.EM_Frame)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Slice}
                    isSelect={isSelect(EditorMode.EM_Slice)}
                    forwardChildren={<MonoIconToolbarSlice24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Slice)}
                >
                    {getEditorModeName(EditorMode.EM_Slice)}
                </DropdownV2.MenuSingleLevel.Option>
            </DropdownV2.MenuSingleLevel>
            <DropdownV2.MenuSingleLevel
                style={{ borderRadius: 'var(--wk-radius-none)' }}
                placement="bottom center"
                onChange={onEnterChangeEditMode}
                label={drawTool.icon}
                onOpen={() => {
                    frogExposeToolsMenusAndActionsMenuDisplay({
                        doc_mode: {
                            is_readonly: !!docReadonly,
                            is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                            is_history_version: !!isHistoryMode,
                            is_vector: !!isEditVector,
                        },
                        displayed_menu: '形状工具下拉菜单',
                    })
                }}
                tooltipProps={{ title: translation('ShapeTools') }}
                labelMoveOut={{
                    isSelect: !isPluginPopup && drawTool.editorMode === editorMode,
                    tooltipProps: {
                        title: getEditorModeName(drawTool.editorMode),
                        shortcut: getEditorModeShortCut(drawTool.editorMode),
                    },
                    onClick: () => {
                        if (drawTool.editorMode === EditorMode.EM_PlaceImage) {
                            onSwitchImage()
                        } else {
                            onSwitchChange(drawTool.editorMode)
                        }
                    },
                }}
                className={
                    editorMode != null &&
                    [
                        EditorMode.EM_Rectangle,
                        EditorMode.EM_Line,
                        EditorMode.EM_Arrow,
                        EditorMode.EM_Ellipse,
                        EditorMode.EM_Polygon,
                        EditorMode.EM_Star,
                        EditorMode.EM_PlaceImage,
                    ].includes(editorMode)
                        ? styles['active-background']
                        : undefined
                }
                dataTestIds={{ triggerFocus: 'draw-select-down' }}
            >
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Rectangle}
                    isSelect={isSelect(EditorMode.EM_Rectangle)}
                    forwardChildren={<MonoIconToolbarRectangle24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Rectangle)}
                    data-testid="draw-rect"
                >
                    {getEditorModeName(EditorMode.EM_Rectangle)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Line}
                    isSelect={isSelect(EditorMode.EM_Line)}
                    forwardChildren={<MonoIconToolbarLine24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Line)}
                    data-testid="draw-line"
                >
                    {getEditorModeName(EditorMode.EM_Line)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Arrow}
                    isSelect={isSelect(EditorMode.EM_Arrow)}
                    forwardChildren={<MonoIconToolbarArrow24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Arrow)}
                    data-testid="draw-arrow"
                >
                    {getEditorModeName(EditorMode.EM_Arrow)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Ellipse}
                    isSelect={isSelect(EditorMode.EM_Ellipse)}
                    forwardChildren={<MonoIconToolbarOval24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Ellipse)}
                    data-testid="draw-ellipse"
                >
                    {getEditorModeName(EditorMode.EM_Ellipse)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Polygon}
                    isSelect={isSelect(EditorMode.EM_Polygon)}
                    forwardChildren={<MonoIconToolbarTriangle24 />}
                    data-testid="draw-polygon"
                >
                    {getEditorModeName(EditorMode.EM_Polygon)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Star}
                    isSelect={isSelect(EditorMode.EM_Star)}
                    forwardChildren={<MonoIconToolbarStar24 />}
                    data-testid="draw-star"
                >
                    {getEditorModeName(EditorMode.EM_Star)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_MenuPlaceImage}
                    isSelect={false}
                    forwardChildren={<MonoIconToolbarImage24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_PlaceImage)}
                    data-testid="place-image"
                >
                    {getEditorModeName(EditorMode.EM_PlaceImage)}
                </DropdownV2.MenuSingleLevel.Option>
            </DropdownV2.MenuSingleLevel>
            <Switch.Item
                id="wk-pen-tool"
                className={`${style.switchItem44}`}
                key={EditorMode.EM_PEN}
                tooltipTitle={getEditorModeName(EditorMode.EM_PEN)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_PEN)}
            >
                <MonoIconToolbarPen24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_Text}
                tooltipTitle={getEditorModeName(EditorMode.EM_Text)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Text)}
                testid="draw-text"
            >
                <MonoIconToolbarType24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_HandTool}
                tooltipTitle={getEditorModeName(EditorMode.EM_HandTool)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_HandTool)}
            >
                <MonoIconToolbarGripper24 />
            </Switch.Item>
            <Switch.Item
                className={`${style.switchItem44} wk-comment-tool`}
                key={EditorMode.EM_Comment}
                tooltipTitle={getEditorModeName(EditorMode.EM_Comment)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Comment)}
                testid="draw-comment"
            >
                <span className={classNames(style.comment)}>
                    <MonoIconToolbarComment24 />
                    <Badge.Dot color="red" stroke style={{ top: 2, right: 2 }} isHide={!hasUnreadComment} />
                </span>
            </Switch.Item>
            <DropdownV2.MenuSingleLevel
                style={{ borderRadius: 'var(--wk-radius-none)' }}
                placement="bottom center"
                onChange={onEnterChangePluginMode}
                label={pluginTool.icon}
                tooltipProps={{ title: translation('Plugins') }}
                onOpen={() => {
                    onPluginPopupChange(true)
                    frogExposeToolsMenusAndActionsMenuDisplay({
                        doc_mode: {
                            is_readonly: !!docReadonly,
                            is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                            is_history_version: !!isHistoryMode,
                            is_vector: !!isEditVector,
                        },
                        displayed_menu: '插件工具下拉菜单',
                    })
                }}
                onClose={() => onPluginPopupChange(false)}
                maxWidth={420}
                dataTestIds={{ triggerFocus: 'plugin-select', scrollContainer: 'plugin-select-container' }}
            >
                {pluginService.officialPlugins.map((plugin) => (
                    <DropdownV2.MenuSingleLevel.Option
                        key={plugin.manifest.key}
                        value={plugin.manifest.key}
                        className={plugin.manifest.icon ? style['icon-item'] : undefined}
                    >
                        <div className={style['item-content']}>
                            <div className={style['item-icon']}>{plugin.manifest.icon ?? null}</div>
                            <span className={style['icon-text']}>{plugin.manifest.name}</span>
                        </div>
                    </DropdownV2.MenuSingleLevel.Option>
                ))}
                {pluginService.getEnabledPluginsForEditor().map((plugin, index) => (
                    <DropdownV2.MenuSingleLevel.Option
                        key={plugin.id}
                        value={plugin.id}
                        className={style['icon-item']}
                        splitLineTop={index === 0}
                        data-testid={`private-plugin-item`}
                        addRightPadding={false}
                    >
                        <OrgPluginItem plugin={plugin} />
                    </DropdownV2.MenuSingleLevel.Option>
                ))}
                {!lastUsedPlugin.disabledLastUsedPlugin && (
                    <DropdownV2.MenuSingleLevel.Option
                        value="last-used-plugin"
                        backwardIcon={shortcutLabelMap[ShortcutKey.RunLastPlugin]}
                        splitLineTop
                    >
                        {translation('RunLastPlugin')}
                    </DropdownV2.MenuSingleLevel.Option>
                )}
                {pluginManagementVisible && (
                    <DropdownV2.MenuSingleLevel.Option value="plugin-management" splitLineTop>
                        {translation('PluginManagement')}
                    </DropdownV2.MenuSingleLevel.Option>
                )}
            </DropdownV2.MenuSingleLevel>
            {getAiTools()}
        </Switch>
    )
}

function OrgPluginItem({ plugin }: { plugin: PluginVO }) {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <div className="h-32px flex items-center justify-start">
            <img src={plugin.iconUrl} className="w-24px h-24px" />
            <Tooltip title={plugin.name} inactivation={inactivation}>
                <div className="mx-8px wk-text-12 truncate">
                    <span ref={ellipsisRef} onMouseEnter={onmouseenter} onMouseLeave={onmouseleave}>
                        {plugin.name}
                    </span>
                </div>
            </Tooltip>
            <div
                className={
                    'box-border h-18px flex items-center justify-center whitespace-nowrap border border-$wk-gray-3 rounded-2px px-3px font-400 text-11px lh-18px text-$wk-gray-8'
                }
            >
                {translation('OrgIcon')}
            </div>
        </div>
    )
}
