import { useVirtualizer } from '@tanstack/react-virtual'
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, PropsWithChildren, Ref, useEffect, useMemo, useRef, useState } from 'react'
import {
    DropdownV2,
    MonoIconCommonAdd16 as IconAdd,
    WKIconButton as IconButton,
    MonoIconMenuColorVariable12 as IconColor,
    MonoIconPanelAdjust16 as IconEditDetail,
    MonoIconMenuNumberVariable12 as IconNumber,
    MonoIconPanelLink16,
    Scrollbar,
    ScrollbarRef,
    Tooltip,
    WKIconButton,
} from '../../../../../ui-lib/src'
import { ToKeyCode } from '../../../document/util/keycode'
import { IN_JEST_TEST } from '../../../environment'
import { isKeyAsEnter } from '../../../kernel/keyboard/keyboard-event-handler'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { useAppContext } from '../../../main/app-context'
import { KeyboardReceiver } from '../../../main/keyboard-receiver/component'
import { useViewState } from '../../../view-state-bridge/use-view-state'
import { InputOptionsForUndoSquash } from '../atom/inputs/components/formatted-input'
import { HexInput } from '../atom/inputs/hex-input'
import { ScrubbableInputPercent } from '../atom/inputs/scrubbable-input-percent'
import { useRenderColorSpace } from '../color-profile/hook'
import { ColorVarIcon } from '../color-var-item/color-var-icon'
import { ColorVarName } from '../color-var-item/color-var-name'
import { hex2rgb, RGB, rgb2hex } from '../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../design/color-interaction/color-interaction'
import { ColorInteractionFrom } from '../design/color-interaction/type'
import { PaintIcon } from '../paint-icon-color/paint-icon/paint-icon'
import { BorderlessInput } from './borderless-input'
import { FloatValueCell } from './float-variable/float-value-cell'
import { FloatValueEditingPopup } from './float-variable/float-value-editing-popup'
import { useEditingDetailState } from './hooks'
import { useLocalVariableDragContext } from './local-variable-drag-context'
import styles from './local-variable-editor-table.module.less'
import { translation } from './local-variable-editor-table.translation'

const FIXED_CELL_WIDTH = 210
const MIN_CELL_WIDTH = 36 + 12 * 2 // 36 是行高，12 是两侧的 padding

function dynamicGridTemplateColumns(modeCount: number) {
    return `${FIXED_CELL_WIDTH}px repeat(${modeCount}, ${FIXED_CELL_WIDTH}px) minmax(${MIN_CELL_WIDTH}px, 1fr)`
}

function isAliasValue(data: Wukong.DocumentProto.ILocalVariableData) {
    return data.dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
}

function convertColorDataToPaint(data: Wukong.DocumentProto.ILocalVariableData) {
    const isAlias = isAliasValue(data)
    return {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
        visible: true,
        opacity: data.resolvedValue.colorValue?.a / 255,
        color: data.resolvedValue.colorValue,
        colorVar: isAlias
            ? ({
                  value: {
                      alias: data.alias,
                  },
                  dataType: data.dataType,
                  resolvedDataType: data.resolvedDataType,
              } as Wukong.DocumentProto.IVariableAliasData)
            : undefined,
    }
}

function useCurrentCollection() {
    return useViewState('localVariableCollection')?.currentCollection ?? null
}

function useSelectedVariables() {
    const tableViewState = useViewState('localVariableTable')
    return tableViewState?.items.filter((item) => item.variableSelected) ?? []
}
function useSelectedVariableIds() {
    return useSelectedVariables().map((item) => item.variableData.id)
}
function useVariableRenamingState() {
    const renamingState = useViewState('localVariableEditorRenamingState')
    if (
        renamingState?.type ===
        Wukong.DocumentProto.LocalVariableEditorRenamingType.LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_VARIABLE
    ) {
        return renamingState?.renamingVariableId
    }
    return null
}

// 表格行同意样式
function _TableRow(
    props: PropsWithChildren<
        {
            selected?: boolean
        } & HTMLAttributes<HTMLDivElement>
    >,
    ref: Ref<HTMLDivElement>
) {
    const { selected = false, className, children, ...otherProps } = props
    return (
        <div
            ref={ref}
            className={classNames(styles['table-row'], selected && styles.selected, className)}
            {...otherProps}
        >
            {children}
        </div>
    )
}
const TableRow = forwardRef(_TableRow)

// 表格单元格统一样式
function _TableCell(props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>, ref: Ref<HTMLDivElement>) {
    const { children, className, ...otherProps } = props
    return (
        <div ref={ref} className={classNames(styles['table-cell'], className)} {...otherProps}>
            {children}
        </div>
    )
}
const TableCell = forwardRef(_TableCell)

// 表头中展示变量合集模式的单元格
function VariableSetModeCell(props: { setMode: Wukong.DocumentProto.IVariableSetMode }) {
    const service = useAppContext().variableService.localVariableEditorService
    // 当前合集
    const selectedCollection = useCurrentCollection()
    // 展示的名称
    const nameValue = (selectedCollection?.modes.length || 0) > 1 ? props.setMode.modeName : translation('单值模式标签')
    // 是否允许编辑模式
    const disableEditing = (selectedCollection?.modes.length || 0) <= 1
    // 重命名编辑状态
    const [isEditing, setIsEditing] = useState(false)
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    return (
        <>
            <TableCell
                data-testid="local-variable-editor-table-header-mode"
                className={classNames(!disableEditing && !!contextMenuState ? styles['table-cell-focused'] : undefined)}
                onDoubleClick={() => {
                    if (disableEditing) {
                        return
                    }
                    setIsEditing(true)
                }}
                onContextMenu={(e) => {
                    if (disableEditing) {
                        return
                    }
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    e.preventDefault()
                }}
            >
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {isEditing ? (
                        <BorderlessInput
                            style={{ color: 'var(--wk-v2-label-color-gray-13)' }}
                            initValue={nameValue}
                            submit={(value) => {
                                service.renameCollectionMode(props.setMode, value)
                                setIsEditing(false)
                            }}
                            abort={() => {
                                setIsEditing(false)
                            }}
                            data-testid="local-variable-editor-table-header-mode-name-input"
                        />
                    ) : (
                        nameValue
                    )}
                </div>
            </TableCell>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('复制模式'): {
                                service.duplicateCollectionMode(props.setMode)
                                return
                            }
                            case translation('重命名模式'): {
                                setIsEditing(true)
                                return
                            }
                            case translation('删除模式'): {
                                service.deleteCollectionMode(props.setMode.modeId!)
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-header-mode-context-menu',
                    }}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('复制模式')}>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-duplicate">
                            {translation('复制模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('重命名模式')} splitLineBottom>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-rename">
                            {translation('重命名模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除模式')}>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-delete">
                            {translation('删除模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

function ColorValueEditingPopup() {
    const service = useAppContext().variableService.localVariableEditorService
    // 颜色编辑状态
    const editingState = useViewState('localVariableEditorEditingState')
    const openFrom =
        editingState &&
        ({
            [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NONE]: null,
            [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_DETAIL]: null,
            [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE]:
                ColorInteractionFrom.LOCAL_VARIABLE_EDIT,
            [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS]:
                ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS,
            [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS]:
                null,
        }[editingState.type] as
            | ColorInteractionFrom.LOCAL_VARIABLE_EDIT
            | ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS
            | null)
    const paint = editingState?.editingVariableData ? convertColorDataToPaint(editingState?.editingVariableData) : null
    // 从选择器更改颜色值
    const onChangeColorFromInteraction = (value: RGB, options?: InputOptionsForUndoSquash) => {
        if (!paint) {
            return
        }
        const rgba = { ...value, a: Math.max(0, Math.min(paint.opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        if (!editingState) {
            return
        }
        service.updateColorVariableValue(
            editingState.editingVariableId,
            editingState.editingModeId,
            rgba,
            options?.commitType
        )
    }
    // 绑定颜色变量
    const onChangeColorVar = (value: Wukong.DocumentProto.IVariableAliasData) => {
        if (!editingState) {
            return
        }
        service.setAliasForVariable(editingState.editingVariableId, editingState.editingModeId, value.value.alias!)
    }
    // 更改透明度
    const onChangeOpacity = (opacity: number, options?: InputOptionsForUndoSquash) => {
        if (!paint) {
            return
        }
        const rgba = { ...paint.color, a: Math.max(0, Math.min(opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        if (!editingState) {
            return
        }
        service.updateColorVariableValue(
            editingState.editingVariableId,
            editingState.editingModeId,
            rgba,
            options?.commitType
        )
    }

    // 编辑状态不对
    if (!editingState || !paint || !openFrom) {
        return null
    }
    return (
        <KeyboardReceiver
            style={{ position: 'relative' }}
            keyCode={ToKeyCode.Esc}
            onKeydown={() => (service.closeVariableEditingPopup(), false)}
        >
            <ColorInteraction
                zIndex={200}
                position={editingState.position}
                positionRightBase={false}
                from={openFrom}
                paint={paint}
                styleId={undefined}
                styleKey={''}
                onCancel={service.closeVariableEditingPopup}
                onChangePaintType={() => {}}
                onChangeBlendMode={() => {}}
                onChangeColor={onChangeColorFromInteraction}
                onChangeOpacity={onChangeOpacity}
                onChangeImagePaint={() => {}}
                onChangeColorStops={() => {}}
                onChangeTransform={() => {}}
                enterClose={service.closeVariableEditingPopup}
                onChangeStyle={() => {}}
                onChangeColorVar={onChangeColorVar}
                onChangePaints={() => {}}
                onClickCreateStyleButton={undefined}
            />
        </KeyboardReceiver>
    )
}
function _ColorValueCell(
    {
        variable,
        modeId,
        isHovered,
        toggleEditing,
    }: {
        variable: Wukong.DocumentProto.ILocalVariable
        modeId: string
        isHovered: boolean
        toggleEditing: (type: Wukong.DocumentProto.LocalVariableEditorEditingType) => void
    },
    ref: Ref<HTMLDivElement>
) {
    const service = useAppContext().variableService.localVariableEditorService
    // 变量数据
    const data = variable.dataValues[modeId]
    // 是否别名数据
    const isAlias = isAliasValue(data)
    // 颜色值
    const paint = convertColorDataToPaint(data)
    // 颜色空间
    const colorSpace = useRenderColorSpace()
    // 是否展示不透明度输入
    const showOpacityInput = paint.opacity !== 1
    // 更改颜色值
    const onChangeColor = (hex: string) => {
        const color = hex2rgb(hex)
        const rgba = { ...color, a: Math.max(0, Math.min(paint.opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        service.updateColorVariableValue(variable.id, modeId, rgba)
    }
    // 更改透明度
    const onChangeOpacity = (opacity: number, options?: InputOptionsForUndoSquash) => {
        const rgba = { ...paint.color, a: Math.max(0, Math.min(opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        service.updateColorVariableValue(variable.id, modeId, rgba, options?.commitType)
    }
    // 悬浮状态
    const [isHoveredName, setIsHoveredName] = useState(false)
    if (isAlias) {
        return (
            <>
                <ColorVarIcon
                    focusNoneBorder
                    className={styles['color-var-icon']}
                    rgb={paint.color}
                    colorSpace={colorSpace}
                    opacity={paint.opacity}
                    onClick={(e) => {
                        toggleEditing(
                            Wukong.DocumentProto.LocalVariableEditorEditingType
                                .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS
                        )
                        e.stopPropagation()
                    }}
                    ref={ref}
                />
                <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <ColorVarName
                        name={data.aliasName}
                        deleted={data.isSoftDeleted}
                        hovered={isHoveredName}
                        onClickOnTag={(e) => {
                            toggleEditing(
                                Wukong.DocumentProto.LocalVariableEditorEditingType
                                    .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS
                            )
                            e.stopPropagation()
                        }}
                        onHoverName={(hovered) => setIsHoveredName(hovered)}
                    />
                </div>
                {isHovered && (
                    <div className={styles['detach-icon']}>
                        <Tooltip title={translation('分离变量')}>
                            <WKIconButton
                                preventFocus
                                data-testid="local-variable-editor-table-item-color-alias-detach-button"
                                icon={<MonoIconPanelLink16 />}
                                onClick={() => service.detachAliasForVariable(variable, modeId)}
                            />
                        </Tooltip>
                    </div>
                )}
            </>
        )
    }
    return (
        <>
            <PaintIcon
                paint={paint}
                focusNoneBorder
                className={styles['paint-icon']}
                colorSpace={colorSpace}
                onClick={(e) => {
                    toggleEditing(
                        Wukong.DocumentProto.LocalVariableEditorEditingType
                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE
                    )
                    e.stopPropagation()
                }}
                ref={ref}
            />
            <div style={{ display: 'flex' }}>
                <HexInput
                    className={styles['color-input']}
                    value={rgb2hex(paint.color.r, paint.color.g, paint.color.b)}
                    focusWithoutBorder={true}
                    onChange={onChangeColor}
                    testId="local-variable-editor-table-item-color-input"
                />
                {showOpacityInput && (
                    <ScrubbableInputPercent
                        className={styles['opacity-input-control']}
                        inputClassName={styles['opacity-input']}
                        focusWithoutBorder={true}
                        value={paint.opacity}
                        onChange={(value, options) => onChangeOpacity((value as number) / 100, options)}
                        testId="local-variable-editor-table-item-color-opacity"
                    />
                )}
            </div>
        </>
    )
}
const ColorValueCell = forwardRef(_ColorValueCell)

function _VariableValue(
    {
        variable,
        modeId,
        isHovered,
        toggleEditing,
    }: {
        variable: Wukong.DocumentProto.ILocalVariable
        modeId: string
        isHovered: boolean
        toggleEditing: (type: Wukong.DocumentProto.LocalVariableEditorEditingType) => void
    },
    ref: Ref<HTMLDivElement>
) {
    switch (variable.dataType) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return (
                <ColorValueCell
                    variable={variable}
                    modeId={modeId}
                    isHovered={isHovered}
                    ref={ref}
                    toggleEditing={toggleEditing}
                />
            )
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return featureSwitchManager.isEnabled('float-variable') ? (
                <FloatValueCell variable={variable} modeId={modeId} toggleEditing={toggleEditing} />
            ) : null
        default:
            return null
    }
}
const VariableValue = forwardRef(_VariableValue)

// 变量数值单元格
function VariableValueCell({
    variable,
    modeId,
    openDetail,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    modeId: string
    openDetail: (horizontalPosition: number) => void
}) {
    const service = useAppContext().variableService.localVariableEditorService
    // 当前选中的变量
    const selectedVariables = useSelectedVariables()
    // 是否多选
    const isMultiSelected = selectedVariables.length > 1
    // 变量数据
    const data = variable.dataValues[modeId]
    // 是否别名数据
    const isAlias = isAliasValue(data)
    // 编辑状态
    const editingState = useViewState('localVariableEditorEditingState')
    // 编辑位置
    const colorIconRef = useRef<HTMLDivElement>(null)
    const cellRef = useRef<HTMLDivElement>(null)
    // 打开选择器的行为
    // 外部要支持通过右键菜单打开，且要与内部统一行为，所以实现在外面、传入内层组件
    // 还要根据变量类型区分开打开选择器的位置
    const toggleEditing = (type: Wukong.DocumentProto.LocalVariableEditorEditingType): void => {
        switch (variable.dataType) {
            case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR: {
                if (!colorIconRef.current) {
                    return
                }
                // 打开类型与变量类型须匹配
                if (
                    ![
                        Wukong.DocumentProto.LocalVariableEditorEditingType
                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE,
                        Wukong.DocumentProto.LocalVariableEditorEditingType
                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS,
                    ].includes(type)
                ) {
                    return
                }
                // 颜色选择器在 icon 的左下打开
                service.toggleValueEditingPopup(type, variable.id, modeId, {
                    left: colorIconRef.current.getBoundingClientRect().left,
                    top: colorIconRef.current.getBoundingClientRect().bottom,
                })
                break
            }
            case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT: {
                if (!featureSwitchManager.isEnabled('float-variable')) {
                    return
                }
                if (!cellRef.current) {
                    return
                }
                // 打开类型与变量类型须匹配
                if (
                    ![
                        Wukong.DocumentProto.LocalVariableEditorEditingType
                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS,
                    ].includes(type)
                ) {
                    return
                }
                // 数值变量列表弹框在单元格的右上打开
                service.toggleValueEditingPopup(type, variable.id, modeId, {
                    left: cellRef.current.getBoundingClientRect().right,
                    top: cellRef.current.getBoundingClientRect().top,
                })
                break
            }
            default:
                break
        }
    }
    // 悬浮状态
    const [isHovered, setIsHovered] = useState(false)
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)

    if (!data) {
        return null
    }
    return (
        <>
            <TableCell
                data-testid="local-variable-editor-table-item-value"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onContextMenu={(e) => {
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    service.mouseDownToSelectVariable(variable.id, e)
                    e.stopPropagation()
                    e.preventDefault()
                }}
                className={classNames(
                    !!editingState &&
                        editingState.editingModeId === modeId &&
                        editingState.editingVariableId === variable.id &&
                        styles['table-cell-focused']
                )}
                ref={cellRef}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        alignItems: 'center',
                    }}
                >
                    <VariableValue
                        variable={variable}
                        modeId={modeId}
                        isHovered={isHovered}
                        ref={colorIconRef}
                        toggleEditing={toggleEditing}
                    />
                </div>
            </TableCell>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('分离变量'): {
                                service.detachAliasForVariable(variable, modeId)
                                return
                            }
                            case translation('引用其它变量'): {
                                // 右键菜单打开选择器的行为
                                switch (variable.dataType) {
                                    case Wukong.DocumentProto.VariableResolvedDataType
                                        .VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                                        return toggleEditing(
                                            Wukong.DocumentProto.LocalVariableEditorEditingType
                                                .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS
                                        )
                                    case Wukong.DocumentProto.VariableResolvedDataType
                                        .VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                                        if (!featureSwitchManager.isEnabled('float-variable')) {
                                            return
                                        }
                                        return toggleEditing(
                                            Wukong.DocumentProto.LocalVariableEditorEditingType
                                                .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS
                                        )
                                    default:
                                        break
                                }
                                return
                            }
                            case translation('新建分组'): {
                                service.addSelectedVariablesIntoGroup()
                                return
                            }
                            case translation('编辑变量'): {
                                openDetail(contextMenuState.x)
                                return
                            }
                            case translation('复制变量'): {
                                service.duplicateSelectedVariables()
                                return
                            }
                            case translation('删除变量'): {
                                service.removeSelectedVariables()
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-item-context-menu',
                    }}
                >
                    {/* 多选时不展示引用和分离 */}
                    {selectedVariables.length === 1 ? (
                        isAlias ? (
                            <DropdownV2.NoTriggerSingleLevel.Option value={translation('分离变量')}>
                                <span data-testid="local-variable-editor-table-item-context-menu-detach-alias">
                                    {translation('分离变量')}
                                </span>
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        ) : (
                            <DropdownV2.NoTriggerSingleLevel.Option value={translation('引用其它变量')}>
                                <span data-testid="local-variable-editor-table-item-context-menu-create-alias">
                                    {translation('引用其它变量')}
                                </span>
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        )
                    ) : null}
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('新建分组')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-new-group">
                            {translation('新建分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('编辑变量')} onClick={() => {}}>
                        {isMultiSelected ? translation('编辑变量复数') : translation('编辑变量')}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('复制变量')} splitLineBottom>
                        <span data-testid="local-variable-editor-table-item-context-menu-duplicate">
                            {isMultiSelected ? translation('复制变量复数') : translation('复制变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除变量')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-delete">
                            {isMultiSelected ? translation('删除变量复数') : translation('删除变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

function VariableNameIcon({ type }: { type: Wukong.DocumentProto.VariableResolvedDataType }) {
    switch (type) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return <IconColor />
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return <IconNumber />
        default:
            throw new Error(`不支持的变量类型`)
    }
}
// 变量名称单元格
function VariableNameCell({ variable }: { variable: Wukong.DocumentProto.ILocalVariable }) {
    const service = useAppContext().variableService.localVariableEditorService
    // 重命名状态
    const editingVariableId = useVariableRenamingState()
    // 展示的名称
    const nameValue = variable.name.split('/').reverse()[0]
    return (
        <TableCell
            style={{ gap: '8px' }}
            onDoubleClick={() => service.renameVariable(variable.id)}
            data-testid="local-variable-editor-table-item-name"
        >
            <div style={{ width: '12px', height: '12px', color: 'var(--wk-v2-label-color-gray-8)', lineHeight: 1 }}>
                <VariableNameIcon type={variable.dataType} />
            </div>
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre',
                }}
            >
                {editingVariableId === variable.id ? (
                    <BorderlessInput
                        data-testid="local-variable-editor-table-item-name-input"
                        initValue={nameValue}
                        submit={(value) => {
                            service.renameVariableSubmit(value)
                            service.renameVariable(null)
                        }}
                        abort={() => {
                            service.renameVariable(null)
                        }}
                    />
                ) : (
                    nameValue
                )}
            </div>
        </TableCell>
    )
}

// 变量表格行
function VariableRow({
    variable,
    virtualIndex,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    virtualIndex: number
}) {
    const service = useAppContext().variableService.localVariableEditorService
    // 当前合集
    const selectedCollection = useCurrentCollection()
    // 选中的变量
    const selectedVariables = useSelectedVariableIds()
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    // 拖拽状态
    const { dragStart, dragEnterRow, dragLeaveRow } = useLocalVariableDragContext()
    const tableRowRef = useRef<HTMLDivElement>(null)
    const editTableCellRef = useRef<HTMLDivElement>(null)
    // 打开编辑弹框
    const openDetail = (horizontalPosition: number) => {
        const rect = editTableCellRef.current?.getBoundingClientRect?.()
        if (rect) {
            service.startEditVariable(variable.id, {
                top: rect.bottom, // 始终在右键这一行的下方紧挨着
                left: horizontalPosition, // 水平位置由触发场景决定，如右键打开或单击编辑按钮打开
            })
        }
    }
    // 是否打开编辑弹框
    const isOpened = useEditingDetailState()
    // 是否多选
    const isMultiSelected = selectedVariables.length > 1
    // 是否激活编辑按钮
    const highlightEditButton = isOpened && selectedVariables.includes(variable.id) && !isMultiSelected
    return (
        <>
            <TableRow
                ref={tableRowRef}
                data-testid="local-variable-editor-table-item"
                data-selected={selectedVariables.includes(variable.id)}
                selected={selectedVariables.includes(variable.id)}
                style={{
                    gridTemplateColumns: dynamicGridTemplateColumns(selectedCollection?.modes.length ?? 1),
                }}
                onClick={(e) => {
                    // NOTE: 右键菜单 mask 的点击事件也可以出触发这个，但并不在层级内部
                    if (e.currentTarget.contains(e.target as Node)) {
                        service.clickToSelectVariable(variable.id, e)
                    }
                }}
                onMouseDown={(e) => {
                    service.mouseDownToSelectVariable(variable.id, e)
                    if (tableRowRef.current) {
                        dragStart(e, variable, virtualIndex, tableRowRef.current)
                    }
                    e.stopPropagation()
                }}
                onContextMenu={(e) => {
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseEnter={(e) => {
                    if (tableRowRef.current) {
                        dragEnterRow(e, variable, virtualIndex, tableRowRef.current)
                    }
                }}
                onMouseLeave={() => dragLeaveRow()}
            >
                <VariableNameCell variable={variable} />
                {selectedCollection?.modes.map((mode) => (
                    <VariableValueCell
                        key={`${variable.id}-${mode.modeId}`}
                        variable={variable}
                        modeId={mode.modeId!}
                        openDetail={openDetail}
                    />
                ))}
                <TableCell ref={editTableCellRef}>
                    <IconButton
                        data-testid="local-variable-editor-table-item-edit-button"
                        type={highlightEditButton ? 'deepBlue' : 'secondary'}
                        icon={<IconEditDetail />}
                        className={classNames(
                            styles['table-cell-icon-button'],
                            // 只有在单选打开时常驻展示
                            highlightEditButton && styles['table-cell-icon-button-show']
                        )}
                        onClick={(e) => {
                            // 不执行外部表格行的 click 选中
                            e.stopPropagation()
                            const rect = editTableCellRef.current?.getBoundingClientRect?.()
                            if (rect) {
                                openDetail(rect.x - 196)
                            }
                        }}
                    />
                </TableCell>
            </TableRow>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('新建分组'): {
                                service.addSelectedVariablesIntoGroup()
                                return
                            }
                            case translation('编辑变量'): {
                                // 右键打开编辑弹框时，位置跟着右键菜单走
                                openDetail(contextMenuState.x)
                                return
                            }
                            case translation('复制变量'): {
                                service.duplicateSelectedVariables()
                                return
                            }
                            case translation('删除变量'): {
                                service.removeSelectedVariables()
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-item-context-menu',
                    }}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('新建分组')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-new-group">
                            {translation('新建分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('编辑变量')}>
                        {isMultiSelected ? translation('编辑变量复数') : translation('编辑变量')}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option
                        value={translation('复制变量')}
                        backwardIcon={'⇧↩'}
                        splitLineBottom
                    >
                        <span data-testid="local-variable-editor-table-item-context-menu-duplicate">
                            {isMultiSelected ? translation('复制变量复数') : translation('复制变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除变量')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-delete">
                            {isMultiSelected ? translation('删除变量复数') : translation('删除变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

// 表格表头行
function VariableTableHeader() {
    const service = useAppContext().variableService.localVariableEditorService
    // 当前合集
    const selectedCollection = useCurrentCollection()
    return (
        <TableRow
            className={styles['table-row-header']}
            data-testid="local-variable-editor-table-header"
            style={{
                gridTemplateColumns: dynamicGridTemplateColumns(selectedCollection?.modes.length ?? 1),
            }}
        >
            <TableCell>{translation('变量名称')}</TableCell>
            {selectedCollection?.modes.map((setMode) => (
                <VariableSetModeCell key={setMode.modeId} setMode={setMode} />
            ))}
            <TableCell>
                <Tooltip title={translation('添加模式')}>
                    <IconButton
                        icon={<IconAdd />}
                        onClick={service.addCollectionMode}
                        preventFocus
                        data-testid="local-variable-editor-table-header-create-mode-button"
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
function VariableGroupLabel({ groupName }: { groupName: string }) {
    const segments = groupName.split('/')
    const name = segments.pop()
    const path = segments.map((segment) => `${segment} / `)
    return (
        <>
            {path ? <span data-testid="local-variable-editor-table-group-label">{path}</span> : null}
            {name ? <span data-testid="local-variable-editor-table-group-label">{name}</span> : null}
        </>
    )
}
function VariableTableGroupItem({ groupName }: { groupName: string }) {
    const service = useAppContext().variableService.localVariableEditorService
    return (
        <TableRow
            key={groupName}
            className={styles['table-row-group']}
            data-testid="local-variable-editor-table-group"
            onMouseDown={(e) => {
                service.clearVariableSelection()
                e.stopPropagation()
            }}
            onClick={(e) => {
                service.clearPopupState()
                e.stopPropagation()
            }}
        >
            <Tooltip title={groupName}>
                <div className={styles['table-row-group-label']}>
                    <VariableGroupLabel groupName={groupName} />
                </div>
            </Tooltip>
        </TableRow>
    )
}
function VariableTableItem({
    item,
    virtualIndex,
}: {
    item: Wukong.DocumentProto.ILocalVariableTableItem
    virtualIndex: number
}) {
    switch (item.type) {
        case Wukong.DocumentProto.LocalVariableTableItemType.LOCAL_VARIABLE_TABLE_ITEM_TYPE_V_A_R_I_A_B_L_E:
            return <VariableRow variable={item.variableData} virtualIndex={virtualIndex} />
        case Wukong.DocumentProto.LocalVariableTableItemType.LOCAL_VARIABLE_TABLE_ITEM_TYPE_G_R_O_U_P:
            return <VariableTableGroupItem groupName={item.groupName} />
    }
}
function estimateItemSize(item: Wukong.DocumentProto.ILocalVariableTableItem) {
    switch (item.type) {
        case Wukong.DocumentProto.LocalVariableTableItemType.LOCAL_VARIABLE_TABLE_ITEM_TYPE_V_A_R_I_A_B_L_E:
            return 36
        case Wukong.DocumentProto.LocalVariableTableItemType.LOCAL_VARIABLE_TABLE_ITEM_TYPE_G_R_O_U_P:
            return 52
        default:
            return 0
    }
}
export const LocalVariableEditorTable = () => {
    const service = useAppContext().variableService.localVariableEditorService
    // 选中的变量
    const selectedVariables = useSelectedVariables()
    // 表格数据
    const tableData = useViewState('localVariableTable')?.items ?? []
    // 虚拟滚动
    const parentRef = useRef<ScrollbarRef>(null)
    const rowVirtualizer = useVirtualizer({
        count: tableData.length,
        overscan: IN_JEST_TEST ? 200 : undefined,
        getScrollElement: () => {
            if (parentRef.current) {
                return parentRef.current.getContainerElement()
            }
            return null
        },
        estimateSize: (i: number) => estimateItemSize(tableData[i]),
    })
    useEffect(() => {
        return service.registerScrollToIndexCallback((index) => rowVirtualizer.scrollToIndex(index))
    }, [rowVirtualizer, service])
    const items = rowVirtualizer.getVirtualItems()
    const { dragLine } = useLocalVariableDragContext()
    const dragLineOffset = useMemo(() => {
        if (!dragLine.show) {
            return -1
        }
        const row = items.find((item) => item.index === dragLine.index)
        if (!row) {
            return -1
        }
        return dragLine.position === 'before' ? row.start : row.start + row.size
    }, [dragLine, items])
    const showDragLine = dragLineOffset !== -1
    return (
        <>
            <Scrollbar
                className={styles['table-container-scrollbar']}
                ref={parentRef}
                renderViewClassName={styles['table-container-scrollbar-view']}
                scrollHorizontalClassName={styles['table-container-scrollbar-horizontal']}
                scrollVerticalClassName={styles['table-container-scrollbar-vertical']}
            >
                <div
                    style={{ flex: 1 }}
                    onMouseDown={(e) => {
                        if (e.currentTarget === e.target) {
                            service.clearVariableSelection()
                            e.stopPropagation()
                        }
                    }}
                    onClick={(e) => {
                        service.clearPopupState()
                        e.stopPropagation()
                    }}
                    data-testid="local-variable-editor-table-container"
                >
                    <KeyboardReceiver
                        keyCode={ToKeyCode.All}
                        onKeydown={(e) => {
                            if (selectedVariables.length && isKeyAsEnter(e) && e.shiftKey) {
                                service.duplicateSelectedVariables()
                                return false
                            }
                            return true
                        }}
                    >
                        <div
                            className={styles.table}
                            style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
                            data-testid="local-variable-editor-table"
                        >
                            <VariableTableHeader />
                            {items.map((virtualItem) => (
                                <div
                                    key={virtualItem.key}
                                    style={{
                                        position: 'absolute',
                                        top: 36, // 空出表头行
                                        left: 0,
                                        width: '100%',
                                        height: `${virtualItem.size}px`,
                                        transform: `translateY(${virtualItem.start}px)`,
                                    }}
                                    data-index={virtualItem.index}
                                    // 官方推荐的动态调整方法 https://tanstack.com/virtual/latest/docs/api/virtualizer#measureelement-1 但这里不行
                                    // 写了个例子 https://stackblitz.com/edit/demo-react-ts?file=virtual.tsx
                                    // 改为下面这样才行
                                    ref={() =>
                                        rowVirtualizer.resizeItem(
                                            virtualItem.index,
                                            estimateItemSize(tableData[virtualItem.index])
                                        )
                                    }
                                >
                                    <VariableTableItem
                                        item={tableData[virtualItem.index]}
                                        virtualIndex={virtualItem.index}
                                    />
                                </div>
                            ))}
                            {showDragLine && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 100,
                                        top: 36, // 空出表头行
                                        left: 0,
                                        right: 0,
                                        height: '2px',
                                        transform: `translateY(${dragLineOffset}px)`,
                                        backgroundColor: 'var(--wk-gray-13)',
                                    }}
                                />
                            )}
                        </div>
                    </KeyboardReceiver>
                </div>
            </Scrollbar>
            <ColorValueEditingPopup />
            <FloatValueEditingPopup />
        </>
    )
}
