import { PluginApiRemoveNode } from '@wukong/bridge-proto'
import constate from 'constate'
import { zip } from 'lodash-es'
import { useCallback } from 'react'
import { useParams } from 'react-router'
import { useEffectOnce } from 'react-use'
import { TraceableAbortController } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommitType } from '../../../../document/command/commit-type'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { useAppContext } from '../../../../main/app-context'
import { useCommand } from '../../../context/document-context'
import { useAIGenUIFrog } from './ai-gen-ui-princi'
import { useCreateBaseFrame } from './create-base-frame'
import { useAIGenExtraTools } from './use-ai-gen-extra-tools'
import { useAIGenUIInputs } from './use-ai-gen-inputs'
import { usePlatformAndDesignSystem } from './use-ai-gen-platform-and-design-system'
import { useAIGenUIConfigId, useAIGenUITasks, useAIGenUITextArea, useAIGenUIUsedPrompts } from './util'
import { AIGenMetric } from '../../../../kernel/request/ai-gen'

export function useShowAIGenUIModal() {
    const ctx = useAppContext()
    const aiService = ctx.aiService
    const showAIGenUIModal = aiService.useZustandStore.use.showAIGenUIModal()

    return {
        showModal: showAIGenUIModal !== null,
        initPrompt: showAIGenUIModal?.initPrompt,
    } as const
}

export function useAIGenUI() {
    const aiService = useAppContext().aiService
    const { initPrompt, image, styleConfigID, imageMetadata, styleConfigName } =
        aiService.useZustandStore.use.showAIGenUIModal() ?? {}
    const {
        setShouldRetry,
        prompt,
        setWaitForResult,
        shouldRetry,
        examples,
        uploadedImageUrl,
        waitForResult,
        updatePrompt,
        MAX_PROMPT_LENGTH,
        ...aiGenUIInputs
    } = useAIGenUIInputs({ initPrompt, image, styleConfigID, imageMetadata })

    const { getStyleConfig, ...aiGenPlatformAndDesignSystemRest } = usePlatformAndDesignSystem({
        styleConfigID,
        styleConfigName,
    })

    const params = useParams()
    const docId = params.docId || ''
    const { ref, selectInput } = useAIGenUITextArea()
    const { changeConfigId, configs, appendConfig, removeOneConfig } = useAIGenUIConfigId()
    const {
        eventAiGenUIGenerationFail,
        eventAiGenUIGenerationSuccess,
        clickAiGenUIGenerateButton,
        clickAiGenUIRetryButton,
        closeAiGenUIAiGenUiDialog,
        eventAiGenUIPromptEnter,
        eventAiGenUIGenerationTaskSuccess,
    } = useAIGenUIFrog()
    const { usedPrompts } = useAIGenUIUsedPrompts()
    const command = useCommand()
    const { spawnATask, cancelAllRunningTasks, taskQuantity, finishedTaskCount, clearMetrics } = useAIGenUITasks()
    const createBaseFrame = useCreateBaseFrame()

    useAIGenExtraTools({
        examples,
        applyPrompt: async (input) =>
            spawnATask({ ...input, getStyleConfig }, new TraceableAbortController('Multi Gen AI')),
        setWaitForResult: (s) => setWaitForResult(s),
        configs,
        onFinish: () => clearMetrics(),
    })

    const onClose = useCallback(() => {
        closeAiGenUIAiGenUiDialog()
        // On Close, If Current Prompt has already been Gen, we will not record
        if (!usedPrompts.current.has(prompt)) {
            eventAiGenUIPromptEnter(prompt)
        }
        cancelAllRunningTasks()
        setShouldRetry(false)
    }, [cancelAllRunningTasks, closeAiGenUIAiGenUiDialog, eventAiGenUIPromptEnter, prompt, setShouldRetry, usedPrompts])

    const callAIGen = useCallback(async () => {
        usedPrompts.current.add(prompt)
        eventAiGenUIPromptEnter(prompt)
        if (shouldRetry) {
            clickAiGenUIRetryButton()
        } else {
            clickAiGenUIGenerateButton()
        }
        setShouldRetry(false)
        setWaitForResult(true)

        WkCLog.log(`[AI GEN UI] CLICK ${shouldRetry ? 'RETRY' : 'GEN'} BTN`)

        const modelVersions: string[] = ['v1', 'v2']
        const withDefaultConfigs = configs.length === 0 ? [undefined] : configs

        // 是否扣除credit
        let oneSucceed = false

        const baseFrames: string[] = []

        let baseFrameCreated = false

        const isBaseFrameCreated = () => baseFrames

        const createBaseFrames = async (width = 390, height = 844) => {
            if (!baseFrameCreated) {
                baseFrameCreated = true
                baseFrames.push(
                    await createBaseFrame(prompt, width, height),
                    await createBaseFrame(prompt, width, height)
                )
            }
        }

        const runTask = async (
            [configId, version, abc]: readonly [number | undefined, string | undefined, AbortController],
            index: number
        ): Promise<boolean> => {
            const removeBaseFrame = () => {
                if (baseFrames.length > index) {
                    command.invokeBridge(CommitType.Noop, PluginApiRemoveNode, { nodeId: baseFrames[index] })
                }
            }
            abc.signal.addEventListener('abort', removeBaseFrame)
            abc.signal.throwIfAborted()
            try {
                const input = {
                    docId,
                    prompt,
                    configId,
                    version,
                    promptImageUrl: uploadedImageUrl,
                    isBaseFrameCreated,
                    createBaseFrames,
                    ith: index,
                    getStyleConfig,
                }
                const taskId = await spawnATask(input, abc)
                abc.signal.removeEventListener('abort', removeBaseFrame)
                if (!abc.signal.aborted) {
                    const metricName = 'create_task_node'
                    const metricValue = JSON.stringify({
                        taskId,
                        nodeId: baseFrames[index],
                    })
                    const metricReq = new AIGenMetric(docId, metricName, metricValue)
                    await metricReq.start().catch(() => {
                        WkCLog.log('[AI_GEN_UI] AI GEN METRIC REQUEST FAILED', { docId, metricName, metricValue })
                    })
                    if (!oneSucceed) {
                        oneSucceed = true
                        aiService.decreaseAiLabCredits(true)
                    }
                    eventAiGenUIGenerationSuccess()
                    if (taskId) {
                        WkCLog.log('[AI_GEN_UI] AI GEN FINISHED', { taskId })
                        return true
                    }
                }
                return false
            } catch (e) {
                // Cancel Error 也会在这里...
                if (e instanceof Error && e.message.startsWith('Request has been cancelled')) {
                    return false
                }
                abc.abort(e)
                setShouldRetry((previousShouldRetry) => {
                    if (previousShouldRetry === false) {
                        return 'one-failed'
                    }
                    return 'either-failed'
                })
                eventAiGenUIGenerationFail()
                return false
            }
        }

        // 预先创建baseFrame是因为: 现在会生成两版内容: v1, v2, 同时 @chenzhenghao 要求 v1 一定要在 v2 的左边, 为了保证这个顺序, 目前是生成两个baseFrame根据代码执行顺序保证
        const prepareTask = (value: [number | undefined, string | undefined]) => {
            return [
                ...value,
                new TraceableAbortController('AI GEN Will cancel by: "close", "umount", remove base frames'),
            ] as const
        }

        // 因为是Zip的, 后端在生成时会以传入的参数覆盖config中的参数
        // baseFrame将在prepareTask中创建, 与Promise.all无关
        const allSuccess = await Promise.all(zip(withDefaultConfigs, modelVersions).map(prepareTask).map(runTask))
        if (allSuccess.every((s) => s)) {
            eventAiGenUIGenerationTaskSuccess()
        }
        clearMetrics()
        selectInput()
        setWaitForResult(false)
    }, [
        usedPrompts,
        prompt,
        eventAiGenUIPromptEnter,
        shouldRetry,
        setShouldRetry,
        setWaitForResult,
        configs,
        clearMetrics,
        selectInput,
        clickAiGenUIRetryButton,
        clickAiGenUIGenerateButton,
        createBaseFrame,
        command,
        docId,
        uploadedImageUrl,
        getStyleConfig,
        spawnATask,
        eventAiGenUIGenerationSuccess,
        aiService,
        eventAiGenUIGenerationFail,
        eventAiGenUIGenerationTaskSuccess,
    ])

    useEffectOnce(function callAIGenIfExistsInitPrompt() {
        const abc = new TraceableAbortController('Remove Event listener from document')
        document.addEventListener('close', onClose, { signal: abc.signal })

        if (initPrompt && !waitForResult) {
            setWaitForResult(true)
            callAIGen()
            return () => {
                setWaitForResult(false)
                cancelAllRunningTasks()
                abc.abort('Remove due to cleanup as init run')
            }
        }

        return () => {
            abc.abort('Remove due to cleanup')
        }
    })

    return {
        taskQuantity,
        finishedTaskCount,
        examples,
        prompt,
        callAIGen,
        updatePrompt,
        waitForResult,
        cancel: cancelAllRunningTasks,
        shouldRetry,
        ref,
        promptOverLimit: prompt.length >= MAX_PROMPT_LENGTH,
        changeConfigId,
        onClose,
        setWaitForResult,
        configs,
        appendConfig,
        removeOneConfig,
        MAX_PROMPT_LENGTH,
        ...aiGenUIInputs,
        ...aiGenPlatformAndDesignSystemRest,
    } as const
}

export const [AIGenUIContext, useAIGenUIInContext] = constate(useAIGenUI)
