/**
 * @owner: jiangzg@kanyun.com
 */

import classNames from 'classnames'
import { CSSProperties, forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react'
import { createPortal } from 'react-dom'
import { MonoIconCommonClose16 } from '../../icons-v2'
import { useUnmountHref } from '../../utils/hook/use-unmount-href'
import { randomString } from '../../utils/random'
import { getInnerText } from '../../utils/react-node'
import { useModalContext } from '../lib-config-provider'
import { Resize } from '../resize/resize'
import { TabLoop } from '../tab-loop/tab-loop'
import { WKButton, WKButtonProps } from '../wk-button/button'
import { WKIconButton, WKIconButtonProps } from '../wk-button/icon-button'
import { translation } from './index.translation'
import { useWKDialogMarginTop } from './use-wk-dialog-margin-top'
export interface WKDialogProps {
    visible: boolean
    children: React.ReactNode
    onCancel?: () => void
    onCloseIconCancel?: () => void // 关闭按钮的点击回掉，大部分情况是和副按钮一样的，但是架不住有自定义需求
    onOk?: () => void
    width?: number
    centered?: boolean
    testId?: string
    rootTestId?: string
    closable?: boolean
    title?: ReactNode
    titleRender?: ReactNode
    footer?: ReactNode
    footerLeft?: ReactNode
    okButtonProps?: Partial<WKButtonProps>
    cancelButtonProps?: Partial<WKButtonProps>
    okText?: string
    bodyStyle?: CSSProperties
    style?: CSSProperties
    maskStyle?: CSSProperties
    cancelText?: string
    className?: string
    hideFooterLine?: boolean
    cancelIconProps?: Partial<WKIconButtonProps>
    dialogId?: string
    showTitle?: boolean
    maskClosable?: boolean
    tabIndex?: number
    containerClassName?: string
    enabledMarginTop?: boolean
    tabLoop?: boolean // tab 循环
    escClosable?: boolean // 键盘 esc 关闭弹窗
    titlePadding16?: boolean // 设计规范是16，目前是13。先指定个参数和规范对齐
    hideTitleLine?: boolean
    closeWhenHrefChanged?: boolean
    onDragOverFullScreen?: (e: React.DragEvent<HTMLDivElement>) => void
    onDragLeaveFullScreen?: (e: React.DragEvent<HTMLDivElement>) => void
    onDropFullScreen?: (e: React.DragEvent<HTMLDivElement>) => void
}

export interface DialogRef {
    getContainerElement: () => HTMLDivElement | null
}

export const _WKDialog = forwardRef(function Component(
    props: Omit<WKDialogProps, 'visible'>,
    dialogRef: React.Ref<DialogRef>
) {
    const {
        title,
        footer,
        footerLeft,
        titleRender,
        children,
        width = 400,
        onCancel,
        okText,
        cancelText,
        className,
        hideFooterLine,
        cancelIconProps,
        style,
        maskStyle,
        dialogId,
        showTitle = true,
        closable = true,
        centered,
        maskClosable = false,
        containerClassName = '',
        tabIndex,
        enabledMarginTop,
        escClosable,
        titlePadding16,
        onCloseIconCancel,
        hideTitleLine = false,
        closeWhenHrefChanged,
        onDragOverFullScreen,
        onDragLeaveFullScreen,
        onDropFullScreen,
    } = props
    const { ref, marginTop, onChangeResize } = useWKDialogMarginTop(enabledMarginTop)

    // esc 快捷键关闭弹窗
    useEffect(() => {
        function closeWhileElementTop(e: KeyboardEvent) {
            if (e.key !== 'Escape') {
                return
            }

            const element = ref.current
            if (!element) {
                return
            }

            // 当前弹窗内部有焦点元素时，不关闭弹窗
            const activeElement = document.activeElement
            if (activeElement && element.contains(activeElement)) {
                return
            }

            const rect = ref.current.getBoundingClientRect()
            const centerX = rect.left + rect.width / 2
            const centerY = rect.top + rect.height / 2

            // 中心点不在视窗内时，不关闭弹窗
            if (centerX < 0 || centerY < 0 || centerX > window.innerWidth || centerY > window.innerHeight) {
                return
            }

            const topElement = document.elementFromPoint(centerX, centerY)

            // 弹窗位于最顶层时，关闭弹窗
            if (element === topElement || element.contains(topElement)) {
                onCancel?.()
            }
        }

        if (escClosable) {
            window.addEventListener('keydown', closeWhileElementTop)
        }

        return () => {
            window.removeEventListener('keydown', closeWhileElementTop)
        }
    }, [escClosable, onCancel, ref])

    useUnmountHref(() => closeWhenHrefChanged && onCancel?.())

    useImperativeHandle(dialogRef, () => ({
        getContainerElement: () => ref.current,
    }))

    return (
        <div
            data-id={dialogId}
            tabIndex={tabIndex}
            style={maskStyle}
            onClick={() => maskClosable && onCancel?.()}
            className={classNames(
                'fixed top-0 left-0 w-full h-full bg-$wk-v2-fill-color-gray-opacity-10 flex justify-around z-800',
                className
            )}
            data-testid={props.rootTestId}
            onDragOver={onDragOverFullScreen}
            onDragLeave={onDragLeaveFullScreen}
            onDrop={onDropFullScreen}
        >
            <div
                ref={ref}
                role="dialog"
                data-testid={props.testId}
                style={{ width, boxShadow: 'var(--wk-shadow-200)', marginTop, ...style }}
                onClick={(e) => e.stopPropagation()}
                className={classNames(
                    'bg-$wk-v2-bg-color-popup h-min max-h-800px mt-15vh rounded-4px',
                    centered ? '!my-auto' : '',
                    containerClassName
                )}
            >
                {enabledMarginTop ? <Resize onChangeResize={onChangeResize} connectWindowResize /> : null}
                {/* 标题 */}
                {showTitle && (
                    <div className={classNames(!hideTitleLine && 'border-b border-color-$wk-v2-stroke-color-gray-2')}>
                        {titleRender !== undefined ? (
                            titleRender
                        ) : (
                            <div
                                className={classNames('py-13px justify-between pr-4 pl-6 flex items-center', {
                                    ['py-16px']: titlePadding16,
                                })}
                            >
                                <div className="wk-text-14 wk-font-medium flex-1" data-testid="dialog-title">
                                    {title}
                                </div>
                                {closable && (
                                    <WKIconButton
                                        data-testid="close-icon"
                                        className="shrink-0"
                                        onClick={() => {
                                            if (onCloseIconCancel) {
                                                onCloseIconCancel()
                                            } else {
                                                onCancel?.()
                                            }
                                        }}
                                        icon={<MonoIconCommonClose16 />}
                                        {...cancelIconProps}
                                    ></WKIconButton>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {/* 内容 */}
                <div style={props.bodyStyle} className={classNames('px-6 pt-6', hideFooterLine ? 'pb-2' : 'pb-6')}>
                    {children}
                </div>
                {/* 底部 */}
                {footer === null ? (
                    <></>
                ) : (
                    <div
                        className={classNames(
                            'py-4 px-6 text-right',
                            !hideFooterLine && 'border-t border-color-$wk-v2-stroke-color-gray-2 border-t-solid',
                            footerLeft ? 'flex justify-between' : ''
                        )}
                    >
                        {footer ? (
                            footer
                        ) : (
                            <>
                                {footerLeft ? footerLeft : null}
                                <div className="flex items-center justify-end">
                                    <WKButton onClick={props.onCancel} type="secondary" {...props.cancelButtonProps}>
                                        {cancelText ?? translation('Cancel')}
                                    </WKButton>
                                    <WKButton onClick={props.onOk} type="primary" {...props.okButtonProps}>
                                        {okText ?? translation('Confirm')}
                                    </WKButton>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
})

export const WKDialog = forwardRef(function Component(props: WKDialogProps, dialogRef: React.Ref<DialogRef>) {
    const { visible, tabLoop, dialogId, ...rest } = props
    const [randomId] = useState<string>(dialogId || randomString(6))
    const { onModalCallback } = useModalContext()

    useEffect(() => {
        onModalCallback?.(visible, { id: randomId, title: getInnerText(props.title) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    if (visible) {
        return createPortal(
            tabLoop ? (
                <TabLoop>
                    <_WKDialog {...rest} dialogId={randomId} ref={dialogRef} />
                </TabLoop>
            ) : (
                <_WKDialog {...rest} dialogId={randomId} ref={dialogRef} />
            ),
            document.body
        )
    }
    return <></>
})
